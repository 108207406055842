/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from "react";
import ThemeContext from "elements/ThemeContext";

import Header from "parts/Header";
import Hero from "parts/Hero";
import Service from "parts/Service";
import Advantage from "parts/Advantage";
import Testimonial from "parts/Testimonial";
import Discuss from "parts/Discuss";
import Footer from "parts/Footer";
import Dark from "parts/Dark";
import Data from "json/landingPage.json";

const LandingPage = (props) => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <>
      <div className={`${darkMode && "dark"}`}>
        <div className="dark:bg-theme-black">
          <Header {...props} />
          <Hero {...props} />
          <Service data={Data.service} />
          <Advantage data={Data.advantage} />
          <Testimonial data={Data.testimonial} />
          <Dark />
          <Discuss />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
