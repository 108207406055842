/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useContext } from "react";

import ThemeContext from "elements/ThemeContext";
import Fade from "react-reveal/Fade";

export default function Service({ data }) {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`${darkMode && "dark"}`}>
      <div className="bg-gray-50 dark:bg-theme-black">
        <div className="container mx-auto pt-20 pb-28">
          <Fade bottom>
            <h1 className="text-5xl text-theme-dark-Blue text-center font-bold dark:theme-blue">
              Our Service
            </h1>
            <p className="font-light text-lg text-theme-light-Blue text-center mb-12 dark:text-white">
              We are ready to scale up your business with our great service.
            </p>
          </Fade>

          <div className="grid grid-rows-3 px-10 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16">
            {data.map((item, index) => (
              <Fade bottom delay={500 * index}>
                <div>
                  <div className="bg-white group rounded-2xl shadow-2xl border border-theme-Blue transform transition duration-500 hover:scale-105 dark:bg-theme-black dark:border-theme-Blue">
                    <img
                      src={item.imageUrl}
                      alt="Service"
                      className="w-full rounded-t-2xl"
                    />
                    <h2 className="text-theme-dark-Blue text-center text-xl py-7 rounded-b-2xl dark:text-white">
                      {item.title}
                    </h2>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
