import React from 'react';
import Footer from 'parts/Footer';
import Header from 'parts/Header';

const PrivacyPolicy = () => (
  <>
    <Header />
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mb-10">
      <h1 className="text-4xl font-semibold text-center mb-6 text-theme-dark-Blue">
        Privacy Policy
      </h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">Introduction</h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          Welcome to ServingSol. We value your privacy and are
          committed to protecting your personal information. This privacy policy
          outlines how we collect, use, and safeguard your data when you use our
          services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">
          Information Collection
        </h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          We collect various types of information, including personal details
          like your name, email address, and usage data when you interact with
          our website or services. This information is collected to enhance your
          experience and provide better services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">
          How We Use Your Information
        </h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          The information we collect may be used to:
          <ul className="list-disc pl-6 mt-4 text-gray-600 text-lg">
            <li>Provide and improve our services.</li>
            <li>Respond to inquiries and support requests.</li>
            <li>Personalize your user experience.</li>
            <li>Send promotional emails or updates (if subscribed).</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">Data Security</h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          We implement a variety of security measures to maintain the safety of
          your personal information when you access our services. These security
          measures include encryption and secure servers to protect your data.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">
          Third-Party Services
        </h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          We may share your information with third-party service providers to
          help us deliver certain features or improve our services. These third
          parties are required to handle your information responsibly.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">Your Rights</h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          You have the right to access, correct, or delete your personal
          information at any time. If you wish to exercise these rights, please
          contact us at [contact email].
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">
          Changes to This Policy
        </h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          We may update this privacy policy from time to time. Any changes will
          be reflected on this page, with the updated date listed at the bottom.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-red-500">Contact Us</h2>
        <p className="text-gray-600 text-lg leading-relaxed">
          If you have any questions or concerns about our privacy policy or how
          we handle your information, please contact us at servingsol1@gmail.com.
        </p>
      </section>

    </div>
    <Footer />
  </>
);

export default PrivacyPolicy;
