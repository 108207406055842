/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React, { useContext } from "react";

import ThemeContext from "elements/ThemeContext";

import { SunIcon, MoonIcon } from "@heroicons/react/solid";

function Dark() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  return (
    <div className={`${darkMode && "dark"}`}>
      <div className="relative">
        <button
          type="button"
          className="fixed bottom-8 right-8 z-10 text-lg px-2 py-2  bg-theme-dark-Blue text-white rounded-full shadow-lg hover:bg-gray-900  dark:bg-white dark:bg-theme-dark-Blue text-black dark:hover:bg-white dark:hover:border-white dark:border-2 transition duration-200 flex items-center justify-center focus:outline-none border-0"
          onClick={toggleDarkMode}
        >
          {darkMode ? (
            <SunIcon className="w-6 h-6 text-yellow-300" />
          ) : (
            <MoonIcon className="w-6 h-6 text-white-500" />
          )}
        </button>
      </div>
    </div>
  );
}

export default Dark;
