/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from "react";

import ThemeContext from "elements/ThemeContext";

import Fade from "react-reveal/Fade";

import Team from "assets/images/Team.png";

export default function HeroTeam() {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`${darkMode && "dark"}`}>
      <section className="hero sm:items-center lg:items-start sm:flex-row dark:bg-theme-black">
        <Fade bottom>
          <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
            <h1 className="text-6xl text-theme-dark-Blue font-bold leading-tight mb-5">
              Our Team
            </h1>
            <p className="font-light text-xl text-theme-light-Blue leading-relaxed dark:text-white">
              Our team is full of passionate people and ready to make your dream
              software come true.
            </p>
          </div>
          <div className="w-full sm:w-1/2 sm:pr-12">
            <img src={Team} alt="Hero" />
          </div>
        </Fade>
      </section>
    </div>
  );
}
