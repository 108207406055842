/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from "react";
import ThemeContext from "elements/ThemeContext";

import Fade from "react-reveal/Fade";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// eslint-disable-next-line import/no-extraneous-dependencies
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default function Testimonial({ data }) {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`${darkMode && "dark"}`}>
      <Fade bottom>
        <section className="container mx-auto">
          <h1 className="text-5xl text-theme-dark-Blue text-center font-bold">
            Testimonials
          </h1>

          <p className="font-light text-lg text-theme-light-Blue text-center mb-3 sm:mb-3 xl:mb-4 dark:text-white">
            What they said about us.
          </p>

          <Splide
            options={{
              type: "loop",
              autoplay: true,
            }}
          >
            {data.map((item) => (
              <SplideSlide>
                <div className="flex-col xl:w-4/5 rounded-2xl shadow-xl sm:shadow-2xl border border-theme-dark-Blue px-8 py-6 mx-2 mb-6 mt-6 xl:mx-auto sm:mx-6 sm:mb-12 dark:bg-theme-black dark:border-theme-Blue">
                  <div className="flex items-center mb-5">
                    <img
                      src={item.imageUrl}
                      alt="Testimoni"
                      className="w-20 h-20 rounded-full"
                    />
                    <div className="flex-col pl-5">
                      <h2 className="text-theme-Blue text-2xl">{item.name}</h2>
                      <p className="font-light text-gray-400 dark:text-white">
                        {item.company}
                      </p>
                    </div>
                  </div>
                  <p className="font-light text-2xl text-theme-light-Blue pl-5 pt-3 pb-1 dark:text-white">
                    {item.testimoni}
                  </p>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </section>
      </Fade>
    </div>
  );
}
