/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useState, useEffect } from "react";
import ThemeContext from "elements/ThemeContext";

import Header from "parts/Header";
import HeroPortfolio from "parts/HeroPortfolio";
import Discuss from "parts/Discuss";
import Footer from "parts/Footer";
import Dark from "parts/Dark";
import Portfolio from "parts/Portfolio";
import Data from "json/landingPage.json";

const Loader = () => (
  <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-theme-black">
    <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
  </div>
);

const ProjectPage = (props) => {
  const { darkMode } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className={darkMode ? "dark" : ""}>
        <div className={darkMode ? "dark:bg-theme-black" : ""}>
          <Header {...props} />
          <HeroPortfolio {...props} />
          <Portfolio data={Data.portfolio} />
          <Discuss {...props} />
          <Footer />
          <Dark />
        </div>
      </div>
    </>
  );
};

export default ProjectPage;
